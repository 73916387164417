<template>
  <div
    id="groupNewModel"
    class="page-container"
  >
    <b-row>
      <b-col
        xs="12"
        sm="4"
        md="3"
        lg="3"
        xl="3"
      >
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <label class="font-weight-600">
              1. Ảnh đại diện bài viết
            </label>
          </b-col>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-5px text-center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="handler_open_button(1)"
            >
              <feather-icon
                icon="CloudIcon"
                class="mr-50"
              />
              Chọn ảnh
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              size="sm"
              class="ml-50"
              :hidden="hiddenDeleteImage"
              @click="handler_func_button(1)"
            >
              <feather-icon
                icon="CloudIcon"
                class="mr-50"
              />
              Xóa ảnh
            </b-button>
          </b-col>
          <n-show-file-from-file-path
            :d-data-root="dataImage"
          />
          <library-modal
            :id-library-model="idLibraryModal"
            @handleCloseLibraryModal="handler_closed_library_model"
          />
        </b-row>
      </b-col>
      <b-col
        xs="12"
        sm="8"
        md="9"
        lg="9"
        xl="9"
      >
        <!--        Tiêu đề bài viết-->
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <label class="font-weight-600">
              2. Tiêu đề bài viết <span class="nRequired">(*)</span>
            </label>
          </b-col>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-5px"
          >
            <input
              id="nc_id_tieude"
              v-model="queryBody.name"
              :placeholder="'Nhập tiêu đề bài viết ... '"
              autocomplete="off"
              autofocus
              class="form-control"
            />
            <small
              v-if="!$v.queryBody.name.required"
              class="text-danger"
            >
              Không được bỏ trống</small>
            <small
              v-if="!$v.queryBody.name.maxLength"
              class="text-danger"
            >
              Tên bài viết có độ dài tối đa
              {{ $v.queryBody.name.$params.maxLength.min }} ký tự.</small>
          </b-col>
        </b-row>
        <!--        Từ khóa SEO-->
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-10px"
          >
            <label class="font-weight-600">
              3. Từ khóa SEO
            </label>
          </b-col>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-5px"
          >
            <input
              id="nc_id_keySeo"
              v-model="queryBody.keySeo"
              :placeholder="'Nhập từ khóa SEO (các từ khóa cách nhau bởi dấu , '"
              class="form-control"
            />
          </b-col>
        </b-row>
        <!--        Tóm tắt bài viết-->
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-10px"
          >
            <label class="font-weight-600">
              4. Tóm tắt bài viết
            </label>
          </b-col>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-5px"
          >
            <textarea
              id="nc_id_sumary"
              v-model="queryBody.summary"
              rowspan="5"
              class="form-control"
            >
            </textarea>
            <small
              v-if="!$v.queryBody.summary.maxLength"
              class="text-danger"
            >
              Tóm tắt bài viết có độ dài tối đa
              {{ $v.queryBody.summary.$params.maxLength.min }} ký tự.</small>
          </b-col>
        </b-row>
        <!--        Tắc giả - Nguồn trích dẫn-->
        <b-row>
          <b-col
            xs="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="nmt-10px"
          >
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-10px"
              >
                <label class="font-weight-600">
                  5. Tác giả bài viết
                </label>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-5px"
              >
                <input
                  id="nc_id_author"
                  v-model="queryBody.author"
                  :placeholder="'Nhập tác giả bài viết ... '"
                  autocomplete="off"
                  class="form-control"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            xs="12"
            sm="8"
            md="8"
            lg="8"
            xl="8"
            class="nmt-10px"
          >
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-10px"
              >
                <label class="font-weight-600">
                  6. Nguồn trích dẫn
                </label>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-5px"
              >
                <input
                  id="nc_id_sourceLink"
                  v-model="queryBody.sourceLink"
                  :placeholder="'Nhập nguồn trích dẫn bài viết ... '"
                  autocomplete="off"
                  class="form-control"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--        Ngày đăng, trạng thái, loại bài viết-->
        <b-row>
          <!--          Ngày đăng bài-->
          <b-col
            xs="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="nmt-10px"
          >
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-10px"
              >
                <label class="font-weight-600">
                  7. Ngày đăng bài
                </label>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-5px"
              >
                <date-time-picker
                  :key="'nc_id_CreatedDate'"
                  v-model="queryBody.datetimeCreate"
                  @input="() => {}"
                />
              </b-col>
            </b-row>
          </b-col>
          <!--          Trạng thái bài viết-->
          <b-col
            xs="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="nmt-10px"
          >
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-10px"
              >
                <label class="font-weight-600">
                  8. Trạng thái bài viết
                </label>
              </b-col>
              <b-col
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="nmt-5px"
              >
                <b-form-checkbox
                  id="chkXuatBanBaiViet"
                  v-model="vmTrangThaiBaiViet"
                  name="chkXuatBanBaiViet"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Xuất bản bài viết
                </b-form-checkbox>
              </b-col>
              <b-col
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="nmt-5px"
              >
                <b-form-checkbox
                  id="chkChoDuyet"
                  v-model="vmTrangThaiBaiViet"
                  name="chkChoDuyet"
                  value="not_accepted"
                  unchecked-value="accepted"
                >
                  Chờ duyệt
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
          <!--          Loại dữ liệu-->
          <b-col
            xs="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="nmt-10px"
          >
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-10px"
              >
                <label class="font-weight-600">
                  9. Nhóm dữ liệu
                </label>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="nmt-5px"
              >
                <group-new-select
                  v-model="queryBody.categoryId"
                  :group-category="groupCategory"
                  :title-category="'Lựa chọn loại dữ liệu'"
                  :no-title-category="'Không có loại dữ liệu con'"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--        Nội dung bài viết-->
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-10px"
          >
            <label class="font-weight-600">
              8. Nội dung bài viết <span class="nRequired">(*)</span>
            </label>
          </b-col>
        </b-row>
        <content-news
          ref="contentNews"
          :content-news="queryBody.content"
        />
        <!--        <quill-editor-->
        <!--          :id="'quillEditor2'"-->
        <!--          :ref="commonRef"-->
        <!--          v-model="queryBody.content"-->
        <!--          :options="editorOption"-->
        <!--        />-->
        <!--        <library-modal-->
        <!--          :idLibraryModel="'exportHtmlLibraryId'"-->
        <!--          @handleCloseLibraryModal="submitLibraryEvent"-->
        <!--        />-->
        <!--        <n-c-k-editor-->
        <!--          ref="nckEditorContent"-->
        <!--          :quill-id="'nc_id_Content'"-->
        <!--          :quill-content="queryBody.content"-->
        <!--        />-->
        <!--        Button-->
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="nmt-10px text-center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="ml-1"
              @click="handler_func_button(3)"
            >
              <feather-icon
                icon="RotateCcwIcon"
                class="mr-50"
              />
              Quay về
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              class="ml-1"
              :disabled="loading"
              @click="handler_func_button(2)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <div
                v-if="loading"
                class="spinner-border spinner-border-sm"
              ></div>
              <span v-if="loading"> Đang xử lý dữ liệu ...</span>
              <span v-else>Lưu dữ liệu</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BBadge, BButton, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { maxLength, required } from 'vuelidate/lib/validators'
import { axiosApiInstance } from '@core/auth/jwt/axiosApiInstance'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import { ImageDrop } from 'quill-image-drop-module'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
// eslint-disable-next-line import/extensions
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NShowFileFromFilePath from '@/views/ndev-component/component/NShowFileFromFilePath.vue'
import NInformationTitle from '@/views/ndev-component/component/NInformationTitle.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import GroupNewSelect from '@/views/category-manager/group-new/pages/components/GroupNewSelect.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NewContantConstantsApi from '@/views/new-content-manager/constants/NewContantConstantsApi'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'
import ContentNews from '@/views/car-manager/car-catalog/pages/components/contentNews.vue'

// Quill.register('modules/imageDrop', ImageDrop)
// Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    ContentNews,
    quillEditor,
    GroupNewSelect,
    DateTimePicker,
    NInformationTitle,
    NShowFileFromFilePath,
    ModalDeleteNoParam,
    LibraryModal,
    BButton,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    dataImage: {
      type: Object,
      default: _ => {
      },
    },
    groupCategory: {
      type: Number,
      default: 0,
    },
    // mã nhóm dữ liệu bài viết: giới thiệu, tin tức ...
    groupNc: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
      ['image'],
      ['video'],
    ]
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
            },
          },
          imageDrop: true,
          imageResize: true,
        },
        theme: 'snow',
      },
      commonRef: {
        type: Object,
        default: _ => {
        },
      },
      queryBody: {
        name: '',
        keySeo: '',
        summary: '',
        sourceLink: '',
        author: '',
        datetimeCreate: '',
        content: '',
        categoryId: '',
        avatar: '',
        statusId: 0,
      },
      vmTrangThaiBaiViet: 'accepted',
      avatarLink: '',
      modalContent: '',
      modalType: '',
      idLibraryModal: 'idLibraryModal',
      idLibraryDelete: 'idLibraryDelete',
      hiddenDeleteImage: false,
      loading: false,
    }
  },
  computed: {},
  created() {
    this.openEvent()
  },
  mounted() {
    this.openEvent()
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
      keySeo: '',
      summary: {
        maxLength: maxLength(350),
      },
      sourceLink: '',
      author: '',
      createdDate: '',
      content: '',
    },
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_SetBreadCom']),
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handler_open_button(type) {
      switch (type) {
        case 1: {
          // Open library
          this.libraryStore_action_ResetBreadCom()
          this.$bvModal.show(this.idLibraryModal)
          break
        }
        default: {
          break
        }
      }
    },
    handler_closed_library_model(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        this.dataImage = val
        this.queryBody.avatar = val.id
      }
      this.$bvModal.hide(this.idLibraryModal)
    },
    handler_closed_button(type, val) {
      switch (type) {
        case 1: {
          break
        }
        default: {
          break
        }
      }
    },
    async handler_func_button(type, val, data) {
      switch (type) {
        case 1: {
          // Delete Image
          // this.modalContent = 'Bạn chắc chắn muốn xóa ảnh đại diện này ?'
          // this.$bvModal.show(this.idLibraryDelete)
          this.dataImage = null
          this.queryBody.avatar = ''
          break
        }
        case 2: {
          // Lưu dữ liệu
          this.$v.$touch()
          if (this.$v.$invalid) {
            NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
          } else {
            this.loading = true
            const idEvent = this.$route.params.id
            this.queryBody.statusId = this.vmTrangThaiBaiViet === 'accepted' ? 3 : 2
            this.queryBody.groupId = this.groupNc
            this.queryBody.content = this.$refs.contentNews.exportHtmlContent
            if (idEvent === undefined) {
              // Thêm mới
              const iResult = await NCoreHelper.excutePOST(this, NewContantConstantsApi.NEWS_DATA_CREATE, this.queryBody, 'Thêm mới bài viết thành công')
              if (iResult) {
                this.returnUrl()
              }
            } else {
              // Cập nhật
              this.queryBody.id = idEvent
              const iResult = await NCoreHelper.excutePUT(this, NewContantConstantsApi.NEWS_DATA_UPDATE, this.queryBody, 'Cập nhật bài viết thành công')
              if (iResult) {
                this.returnUrl()
              }
            }
          }
          break
        }
        case 3: {
          // Quay về
          this.returnUrl()
          break
        }
        default: {
          break
        }
      }
    },
    returnUrl() {
      switch (this.groupNc) {
        case 1: {
          // Giới thiệu
          this.$router.push({ name: 'nc-gioi-thieu-list' })
          break
        }
        case 2: {
          // Tin tức
          this.$router.push({ name: 'nc-tin-tuc-list' })
          break
        }
        default: {
          break
        }
      }
    },
    async openEvent() {
      const idEvent = this.$route.params.id
      if (idEvent === undefined) {
        const today = new Date()
        this.queryBody.datetimeCreate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      } else {
        // Có dữ liệu => call API chi tiết
        // const iResult = await NCoreHelper.excuteGETById(this, NewContantConstantsApi.NEWS_DATA_GET_DETAIL, idEvent)
        const { data } = await axiosApiInstance.get(`${NewContantConstantsApi.NEWS_DATA_GET_DETAIL}${idEvent}`)
        this.queryBody.name = data.name
        this.queryBody.keySeo = data.keySeo
        this.queryBody.summary = data.summary
        this.queryBody.sourceLink = data.sourceLink
        this.queryBody.author = data.author
        this.queryBody.datetimeCreate = data.datetimeCreate
        this.queryBody.content = data.content
        this.queryBody.categoryId = data.categoryId
        this.queryBody.statusId = data.statusId
        this.queryBody.avatar = data.avatar
        this.dataImage = data.fileViewModal
      }
    },
    async handlerSubmitPage(type) {
      switch (type) {
        case 1: {
          break
        }
        case 2: {
          this.dataUpdate.value = this.exportHtmlContent
          const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_CAR_HTML, this.dataUpdate, 'Cập nhật thành công')
          break
        }
        case 3: {
          this.$bvModal.show('SaoChepThongSoKyThuat')
          break
        }
        default: {
          break
        }
      }
    },
    imageHandler() {
      this.libraryStore_action_SetBreadCom([
        {
          id: 0,
          idFolder: '0',
          name: 'Thư mục gốc',
        },
      ])
      this.$bvModal.show('exportHtmlLibraryId')
    },
    submitLibraryEvent(data) {
      if (data !== undefined) {
        const { quill } = this.$refs[this.commonRef] // lấy ra object quill theo ref - gỉa sử ở đây có nhiều quill editor trong một component
        const caretPosition = quill.getSelection(true)
        quill.insertEmbed(caretPosition.index, 'image', data.imagePath)
      }
      this.$bvModal.hide('exportHtmlLibraryId')
    },
  },
}
</script>
