<template>
  <b-form-group>
    <label class="font-weight-600">{{ numberStt }}. {{ title }} <span
      v-if="checkRequired === true"
      class="required"
    >(*)</span></label>
    <b-form-input
      id="txtName"
      v-model="valueSend"
      placeholder="placeholder"
      autocomplete="off"
    />
    <small
      v-if="!$v.valueSend.required"
      class="text-danger"
    >{{ title }} không được bỏ trống</small>
    <small
      v-if="!$v.valueSend.minLength"
      class="text-danger"
    >{{ title }} có độ dài tối thiểu
      {{ $v.valueSend.$params.minLength.min }} ký tự.</small>
    <small
      v-if="!$v.valueSend.maxLength"
      class="text-danger"
    >{{ title }} có độ dài tối đa
      {{ $v.valueSend.$params.maxLength.min }} ký tự.</small>
  </b-form-group>
</template>
<script>
import {
  BBadge, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    BBadge,
    BFormGroup,
    BFormInput,
  },
  props: {
    numberStt: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    valueMin: {
      type: Number,
      default: 0,
    },
    valueMax: {
      type: Number,
      default: 0,
    },
    checkRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueSend: '',
      iCheckRequired: false,
    }
  },
  mounted() {
    this.iCheckRequired = this.checkRequired
  },
  // validations: {
  //   valueSend: {
  //     required: this.iCheckRequired === true,
  //     minLength: this.valueMin > 0 ? minLength(this.valueMin) : false,
  //     maxLength: this.valueMax > 0 ? maxLength(this.valueMax) : false,
  //   },
  // },
  methods: {
    setName(value) {
      this.valueSend = value
      this.$v.valueSend.$touch()
    },
  },
}
</script>
