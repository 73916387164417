<template>
  <b-modal
    :id="idModalLibraryFolderFunc"
    size="sm"
    title="Chọn chức năng"
    cancel-title="Quay về"
    cancel-variant="outline-primary"
    centered
    :hide-footer="true"
    :hide-header="true"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center font-weight-600"
          style="color: blue;text-transform: uppercase"
        >
          {{ dataSend.name }}
        </b-col>
        <b-col
          xs="12"
          sm="6"
          ms="6"
          lg="6"
          xl="6"
        >
          <b-img
            src="https://freesvg.org/img/cartella.png"
            fluid
            :alt="dataSend.name"
          />
        </b-col>
        <b-col
          xs="12"
          sm="6"
          ms="6"
          lg="6"
          xl="6"
        >
          <b-row>
            <b-col
              xs="12"
              sm="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                size="sm"
                class="form-control nFolder_Title mt-1"
                variant="outline-primary"
                @click="handleCloseFolderFunc(1)"
              >
                <feather-icon
                  icon="MonitorIcon"
                  class="mr-50"
                />
                Xem chi tiết
              </b-button>
            </b-col>
            <b-col
              xs="12"
              sm="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                size="sm"
                class="form-control nFolder_Title mt-1"
                variant="outline-primary"
                @click="handleCloseFolderFunc(2)"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50"
                />
                Cập nhật
              </b-button>
            </b-col>
            <b-col
              xs="12"
              sm="12"
              md="12"
              xl="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                size="sm"
                class="form-control nFolder_Title mt-1"
                variant="outline-primary"
                @click="handleCloseFolderFunc(200)"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  class="mr-50"
                />
                Xóa
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xs="12"
          sm="6"
          ms="6"
          lg="6"
          xl="6"
          class="text-left"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="primary"
            @click="handleCloseModalFunc()"
          >
            <feather-icon
              icon="LogOutIcon"
              class="mr-50"
            />
            Quay về
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    idModalLibraryFolderFunc: {
      type: String,
      default: '',
    },
    dataSend: {
      type: Object,
      default: _ => {},
    },
  },
  methods: {
    async handleCloseFolderFunc(val) {
      this.$emit('handleCloseFolderFunc', val, this.dataSend)
    },
    handleCloseModalFunc() {
      this.$bvModal.hide(this.idModalLibraryFolderFunc)
    },
    resetState() {
    },
  },
}
</script>
