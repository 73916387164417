<template>
  <b-modal
    :id="designEventId"
    :title="designEventTitle"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="md"
    @hidden="resetState"
    @ok="submitEvent"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-form-group>
            <label>Màu sắc</label>
            <v-select
              v-model="formQuery.colorId"
              :options="colorCombobox || []"
              :reduce="colorCombobox => colorCombobox.Key"
              label="Value"
              placeholder="Chọn màu sắc ..."
            >
              <template
                slot="option"
                slot-scope="option"
              >
                {{ $t(option.Value) }}
              </template>
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ $t(option.Value) }}
                </div>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
                xs="12"
              ><label>Ảnh đại diện</label></b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <span>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="handler_open_library(1)"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="CloudIcon"
                    />
                    Chọn ảnh
                  </b-button>
                </span>
                <span>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="ml-1"
                    size="sm"
                    variant="outline-danger"
                    @click="removeFile()"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="EyeIcon"
                    />
                    Xóa ảnh
                  </b-button>
                </span>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                xl="12"
                xs="12"
              >
                <b-img
                  :src="filePath"
                  class="mt-1"
                  fluid
                  style="width: 100%;height: auto;object-fit: cover;"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <library-modal
      :id-library-model="libraryId"
      @handleCloseLibraryModal="handleCloseLibraryModal"
    />
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import { maxLength, required } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import DesignApi from '@/views/car-manager/car-catalog/pages/components/Design/DesignApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    LibraryModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    designEventId: {
      type: String,
      default: '',
    },
    designEventTitle: {
      type: String,
      default: '',
    },
    designEventProp: {
      type: Object,
      default: _ => {
      },
    },
    designEventData: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      designEvent: {
        id: '',
        title: '',
      },
      libraryId: 'libraryModalId',
      colorCombobox: [],
      formQuery: {
        colorId: '',
        carCategoryId: '',
        image: '',
      },
      filePath: '',
      status: 'not_accepted',
      isStart: false,
    }
  },
  watch: {
    // designEventProp(val) {
    //   this.designEvent.id = val.id
    //   this.designEvent.title = val.title
    // },
    // designEventData(val) {
    //   if (val) {
    //     debugger
    //     this.formQuery.colorId = val.colorId
    //     this.formQuery.image = val.image
    //     this.filePath = this.callThumbnail(val.fileViewModal.fileExtension, val.fileViewModal.filePath, val.fileViewModal.fileType)
    //   }
    // },
    designEventData: {
      deep: true,
      handler(val) {
        if (val) {
          this.formQuery.colorId = val.colorId
          this.formQuery.image = val.image
          this.formQuery.id = val.id
          this.filePath = this.callThumbnail(val.fileViewModal.fileExtension, val.fileViewModal.filePath, val.fileViewModal.fileType)
        }
      },
    },
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  mounted() {
    this.callComboboxColor()
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    async callComboboxColor() {
      this.colorCombobox = await NCoreHelper.excuteGET_NoParam(this, DesignApi.COMBOBOX_COLOR)
      this.formQuery.colorId = this.colorCombobox[0].Key
    },
    submitEvent(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('submitEvent', this.formQuery)
    },
    // handleCreateCarCategory(bvModalEvt) {
    //   this.isStart = true
    //   bvModalEvt.preventDefault()
    //   this.$emit('handleCreateCarCategory', this.formQuery)
    // },
    resetState() {
      this.formQuery.colorId = ''
      this.formQuery.carCategoryId = ''
      this.formQuery.image = ''
      this.filePath = ''
      this.isStart = false
    },
    openInput() {
      this.handler_open_library()
    },
    callThumbnail(fileExtension, filePath, fileType) {
      return NCoreHelper.GetThumbnail(false, fileExtension, filePath, '64x64', this.$isServer, NCoreHelper.GetIsImageLocal(fileType))
    },
    handler_open_library(type) {
      this.libraryType = type
      this.libraryStore_action_ResetBreadCom()
      this.$bvModal.show(this.libraryId)
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        this.filePath = this.callThumbnail(val.fileExtension, val.filePath, val.fileType)
        this.formQuery.image = val.id
      }
      this.$bvModal.hide(this.libraryId)
    },
    removeFile(index) {
      //= => Gọi api xóa đi hình ảnh này ======
      this.filePath = ''
      this.formQuery.image = null
    },
  }
  ,
}
</script>
