<template>
  <b-modal
    :id="idModal"
    size="md"
    title="Thêm loại xe"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    :hide-footer="true"
    :hide-header="true"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="mb-1"
        >
          <b-img
            :src="filePath"
            fluid
            class="mt-1"
            style="width: 100%;height: auto;object-fit: cover;"
          />
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-1"
            @click="handlerCloseModal"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            Quay về
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="ml-1"
            @click="handlerDeleteImage"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            Xóa ảnh
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BFormCheckbox, BImgLazy,
} from 'bootstrap-vue'

import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    BImgLazy,
  },
  props: {
    idModal: {
      type: String,
      default: '',
    },
    filePath: {
      type: String,
      default: '',
    },
    hideDeleteImage: {
      type: Boolean,
      default: true,
    },
    dataSend: {
      type: Object,
      default: _ => {
      },
    },
  },
  watch: {
    dataSend: {
      deep: true,
      handler(val) {
        if (val) {
          this.filePath = this.getThumbnail()
        }
      },
    },
  },
  methods: {
    resetState() {
    },
    handlerCloseModal() {
      this.$bvModal.hide(this.idModal)
    },
    getThumbnail() {
      if (this.dataSend.fileType === '2') {
        return this.dataSend.filePath
      }
      return `${this.$serverfile}${this.dataSend.filePath}`
    },
    handlerDeleteImage() {
      this.$emit('handlerDeleteImage', this.dataSend.filePosition)
    },
  },
}
</script>
