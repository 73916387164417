<template>
  <b-modal
    id="SaoChepThongSoKyThuat"
    size="md"
    title="Thêm loại xe"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateCarCategory"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <v-select
            v-model="contentData"
            :options="groupCombobox || []"
            :reduce="groupCombobox => groupCombobox.Key"
            label="Value"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import VSelect from 'vue-select'
import {
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    VSelect,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      contentData: '',
      groupCombobox: [],
      dataUpdate: {
        id: '',
        carCategoryId: '',
        group: 1,
        value: '',
      },
    }
  },
  watch: {
  },
  validations: {
  },
  mounted() {
    this.callCarList()
  },
  methods: {
    async callCarList() {
      this.groupCombobox = await NCoreHelper.excuteGET_NoParam(this, ConstantsApi.GET_CAR_LIST)
    },
    async handleCreateCarCategory(bvModalEvt) {
      this.dataUpdate.carCategoryId = this.contentData
      const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_CAR_HTML, this.dataUpdate, 'Cập nhật thành công')
    },

  },
}
</script>
