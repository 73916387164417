<template>
  <b-modal
    :id="idModalLibraryFolder"
    size="sm"
    title="Thêm dữ liệu html"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateFolder"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <textarea
            id="txtHtml"
            v-model="htmlContent"
          />
          <small
            v-if="!$v.htmlContent.required"
            class="text-danger"
          >
            Không được bỏ trống</small>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { required } from 'vuelidate/lib/validators'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalLibraryFolder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      htmlContent: '',
    }
  },
  validations: {
    htmlContent: {
      required,
    },
  },
  methods: {
    async handleCreateFolder(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
      } else {
        this.$emit('handleInsertHtml', this.htmlContent)
      }
    },
    resetState() {
      this.htmlContent = ''
    },
  },
}
</script>
