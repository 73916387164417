<template>
  <b-modal
    :id="Nv3TitleId"
    :hide-footer="true"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="sm"
    title="Cập nhật tiêu đề"
    @hidden="resetModal"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          class="font-weight-600"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          - Tiêu đề <span
            :hidden="Nv3TitleRequired"
            class="nRequired"
          >(*)</span>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="font-weight-600 mb-1"
        >
          <input
            id="txtTitle"
            v-model="detailFileModels.name"
            :placeholder="Nv3TitlePlace"
            autocomplete="off"
            class="form-control"
          />
        </b-col>
        <b-col
          class="text-center"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <hr />
        </b-col>
        <b-col
          class="text-center"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            :disabled="loading"
            variant="outline-primary"
            @click="handler_click(3)"
          >
            <feather-icon
              class="mr-50"
              icon="RotateCcwIcon"
            />
            Quay về
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="loading"
            class="ml-1"
            size="sm"
            variant="danger"
            @click="handler_click(4)"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></div>
            <span v-if="loading"> Đang xử lý dữ liệu ...</span>
            <span v-else>Lưu dữ liệu</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    rootData: {
      type: Array,
      default: _ => {
      },
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3TitleId: {
      type: String,
      default: 'Nv3TitleId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3TitlePlace: {
      type: String,
      default: 'Nv3TitleId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3TitleType: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3TitleRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Nv3TitleModal: {
        id: '',
        name: '',
        content: '',
        position: 0,
        groupId: '',
        groupFatherId: '',
        typeId: '',
        detailFileModels: null,
      },
      detailFileModels: {
        id: '',
        carCatalogDetailId: '',
        fileId: '',
        filePath: '',
        fileName: '',
        name: '',
        fileType: '',
        fileContent: '',
        filePosition: 0,
      },
      isDeleteImage: false,
      isSaveImage: false,
      loading: false,
      filePath: '',
    }
  },
  watch: {
    rootData: {
      deep: true,
      handler(val) {
        // eslint-disable-next-line prefer-destructuring
        this.Nv3TitleModal = val
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = val.model[0].detailFileModels[0]
      },
    },
  },
  // mounted() {
  //   this.Nv3TitleModal = this.rootData
  // },
  methods: {
    handler_click(type) {
      switch (type) {
        case 1: {
          break
        }
        case 2: {
          break
        }
        case 3: {
          // Quay về
          this.$bvModal.hide(this.Nv3TitleId)
          break
        }
        case 4: {
          // Lưu dữ liệu
          this.loading = true
          this.Nv3TitleModal.model[0].detailFileModels = []
          this.Nv3TitleModal.model[0].detailFileModels.push(this.detailFileModels)
          this.$emit('handClick', this.Nv3TitleType, this.Nv3TitleModal)
          break
        }
        default: {
          break
        }
      }
    },
    resetModal() {
      this.loading = false
      this.detailFileModels.name = ''
    },
  },
}
</script>
