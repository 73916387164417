<template>
  <b-modal
    :id="idModalLibraryFolder"
    size="sm"
    title="Upload File"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateFolder"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <label class="font-weight-600">1. Loại file</label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-checkbox
            v-model="chkLocal"
            value="accepted"
            unchecked-value="not_accepted"
            class="custom-control-danger"
            @change="selectTypeFile(1)"
          >
            Hình ảnh/File từ máy tính
          </b-form-checkbox>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center mt-1"
          :hidden="hiddenLocal"
        >
          <input
            id="file"
            ref="file-second"
            type="file"
            class="d-none"
            @change="uploadServerFile"
          />
          <b-button
            variant="outline-primary"
            class="text-nowrap ml-1"
            size="sm"
            @click="inputFile"
          >
            <div
              class="d-flex"
            >
              <feather-icon
                icon="UploadIcon"
                for="file"
                class="input_label text-primary"
              />
              <label
                class="text-primary ml-50"
              >Chọn file</label>
            </div>
          </b-button>
          <span><br />{{ nameFile }}</span>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="mt-1"
        >
          <b-form-checkbox
            v-model="chkLink"
            value="accepted"
            unchecked-value="not_accepted"
            class="custom-control-danger"
            @change="selectTypeFile(2)"
          >
            Hình ảnh/File từ link khác
          </b-form-checkbox>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center mt-1"
          :hidden="hiddenLink"
        >
          <b-form-input
            id="txtLink"
            v-model="queryBody.differenceLink"
            placeholder="Nhập đường dẫn file ..."
            autocomplete="off"
            @change="handleDifference(1)"
          />
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center"
          style="margin-top: 5px;"
          :hidden="hiddenLink"
        >
          <b-form-checkbox
            v-model="chkLinkAuto"
            value="accepted"
            unchecked-value="not_accepted"
            class="custom-control-success"
          >
            Tự động lấy tên file
          </b-form-checkbox>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="mt-1"
        >
          <b-form-checkbox
            v-model="chkYoutube"
            value="accepted"
            unchecked-value="not_accepted"
            class="custom-control-danger"
            @change="selectTypeFile(3)"
          >
            Link từ Youtube
          </b-form-checkbox>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center mt-1"
          :hidden="hiddenYoutube"
        >
          <b-form-input
            id="txtYoutube"
            v-model="queryBody.linkYoutube"
            placeholder="Nhập đường dẫn link youtube ..."
            autocomplete="off"
            @change="handleDifference(2)"
          />
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center"
          style="margin-top: 5px;"
          :hidden="hiddenYoutube"
        >
          <b-form-checkbox
            v-model="chkYoutubeAuto"
            value="accepted"
            unchecked-value="not_accepted"
            class="custom-control-success"
          >
            Tự động lấy tên file
          </b-form-checkbox>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="mt-1"
        >
          <label class="font-weight-600">2. Tên file <span style="color: red">(*)</span></label>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <b-form-input
              id="txtName"
              v-model="queryBody.name"
              placeholder="Nhập tên file ..."
              autocomplete="off"
              autofocus
              :disabled="disableProp"
            />
            <small
              v-if="!$v.queryBody.name.required"
              class="text-danger"
            >
              Không được bỏ trống</small>
            <small
              v-if="!$v.queryBody.name.maxLength"
              class="text-danger"
            >
              Tên có độ dài tối đa
              {{ $v.queryBody.name.$params.maxLength.min }} ký tự.</small>
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          :hidden="hiddenImage"
        >
          <b-img-lazy
            :src="srcImage"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NFormInput from '@/views/ndev-component/component/NFormInput.vue'
import ServerFileConstantsApi from '@/views/ndev-component/constants/ServerFileConstantsApi'

export default {
  components: {
    NFormInput,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalLibraryFolder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataFolderDetail: {
      type: Array,
      default: _ => {
      },
    },
    disableProp: {
      type: Boolean,
      default: true,
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_BreadCom']),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      queryBody: {
        name: '',
        description: '',
        parentId: this.parentId,
        eventType: '',
        differenceLink: '',
        linkYoutube: '',
      },
      chkLocal: 'not_accepted',
      chkLink: 'not_accepted',
      chkYoutube: 'not_accepted',
      chkLinkAuto: 'accepted',
      chkYoutubeAuto: 'accepted',
      hiddenLocal: true,
      hiddenLink: true,
      hiddenYoutube: true,
      hiddenImage: true,
      nameFile: '',
      srcImage: '',
    }
  },
  validations: {
    queryBody: {
      name: {
        required,
        // minLength: minLength(4),
        maxLength: maxLength(500),
      },
    },
  },
  watch: {
    dataFolderDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            id: this.dataFolderDetail.id,
            name: this.dataFolderDetail.name,
            description: this.dataFolderDetail.description,
            parentId: this.dataFolderDetail.parentId,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            description: '',
            parentId: this.parentId,
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_reloadFolder']),
    async handleCreateFolder(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
      } else {
        // this.$emit('handleCreateFolder', this.queryBody)
        let iEventType = 1
        if (this.chkLink === 'accepted') iEventType = 2
        if (this.chkLocal === 'accepted') iEventType = 1
        if (this.chkYoutube === 'accepted') {
          iEventType = 3
          this.queryBody.differenceLink = this.queryBody.linkYoutube
        }
        this.queryBody.parentId = this.parentId
        this.queryBody.eventType = iEventType
        const iResult = await NCoreHelper.excutePUT(this, ServerFileConstantsApi.SERVER_FILE_UPDATE_FILE, this.queryBody, 'Upload file thành công')
        if (iResult) {
          this.$emit('handleUpdateFolder', iResult)
          this.libraryStore_action_reloadFolder(true)
        }
      }
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    async uploadServerFile(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) {
        return
      }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      // ===Upload file lên server========
      const iResult = await NCoreHelper.uploadServerFile(this, ServerFileConstantsApi.SERVER_FILE_UPLOAD, {
        files: file,
        isSecure: false,
      })
      this.nameFile = this.$refs['file-second'].files[0].name
      this.queryBody.name = this.$refs['file-second'].files[0].name
      this.disableProp = false
      this.srcImage = NCoreHelper.GetThumbnail(false, iResult.data.fileExtend, iResult.data.filePath, '128x128', this.$serverfile)
      this.queryBody.id = iResult.data.id
      this.hiddenImage = false
    },
    handleDifference(type) {
      if (type === 1) {
        // Link khác
        if (this.chkLinkAuto === 'accepted') {
          this.queryBody.name = this.queryBody.differenceLink
        }
        // ====Lấy filetype từ đường dẫn=============
        const iType = NCoreHelper.GetExtendSion(this.queryBody.differenceLink)
        this.srcImage = NCoreHelper.GetThumbnail(false, iType, this.queryBody.differenceLink, '', '', false)
      } else {
        // Youtube
        // eslint-disable-next-line no-lonely-if
        if (this.chkYoutubeAuto === 'accepted') {
          this.queryBody.name = this.queryBody.linkYoutube
        }
        this.srcImage = NCoreHelper.GetThumbnail(false, '.mp4', this.queryBody.differenceLink, '', '', false)
      }
    },
    selectTypeFile(type) {
      this.disableProp = false
      switch (type) {
        case 1: {
          // Local
          this.disableProp = true
          if (this.chkLocal === 'accepted') {
            this.chkLink = 'not_accepted'
            this.chkYoutube = 'not_accepted'
            this.hiddenLocal = false
            this.hiddenLink = true
            this.hiddenYoutube = true
          } else {
            this.hiddenLocal = true
          }
          break
        }
        case 2: {
          // Link
          if (this.chkLink === 'accepted') {
            this.chkLocal = 'not_accepted'
            this.chkYoutube = 'not_accepted'
            this.hiddenLocal = true
            this.hiddenLink = false
            this.hiddenYoutube = true
          } else {
            this.hiddenLink = true
          }
          break
        }
        case 3: {
          // Youtube
          if (this.chkYoutube === 'accepted') {
            this.chkLocal = 'not_accepted'
            this.chkLink = 'not_accepted'
            this.hiddenLocal = true
            this.hiddenLink = true
            this.hiddenYoutube = false
          } else {
            this.hiddenYoutube = true
          }
          break
        }
        default: {
          break
        }
      }
    },
    resetState() {
      this.chkLocal = 'not_accepted'
      this.chkLink = 'not_accepted'
      this.chkYoutube = 'not_accepted'
      this.hiddenLocal = true
      this.hiddenLink = true
      this.hiddenYoutube = true
      this.queryBody.name = ''
      this.srcImage = ''
    },
  },
}
</script>
