<template>
  <b-row>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="font-weight-600 mb-1"
    >
      {{ valueNumber }}. Nội dung <span
        class="nRequired"
        :hidden="quillRequired"
      >(*)</span>
    </b-col>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="mb-1"
    >
      <quill-editor
        :id="quillId"
        :ref="commonRef"
        v-model="modelSend.content"
        :options="editorOption"
        @input="handleInput"
        @change="handleChange($event)"
      />
      <library-modal
        idLibraryModel="idLibraryModel"
        @handleCloseLibraryModal="handleCloseLibraryModal"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapState } from 'vuex'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import LibraryHtmlEvent from '@/views/ndev-component/library/event/LibraryHtmlEvent.vue'

export default {
  components: {
    LibraryHtmlEvent,
    quillEditor,
    BCardCode,
    BCardText,
    LibraryModal,
  },
  props: {
    quillTitle: {
      type: String,
      default: '',
    },
    quillRequired: {
      type: Boolean,
      default: true,
    },
    quillId: {
      type: String,
      default: '',
    },
    valueNumber: {
      type: Number,
      default: 0,
    },
    groupId: {
      type: Number,
      default: 1,
    },
    groupFatherId: {
      type: Number,
      default: 1,
    },
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
  },
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_resetQuillContent']),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
      ['image'],
      ['video'],
    ]
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
              // video: this.handleInsertHtml,
            },
          },
          // toolbar: toolbarOptions,
          // '#toolbar',
        },
        theme: 'snow',
      },
      content: '',
      commonRef: {
        type: Object,
        default: _ => {
        },
      },
      modelSend: {
        id: '',
        name: '',
        content: '',
        position: this.valueNumber,
        groupId: this.groupId,
        groupFatherId: this.groupFatherId,
        typeId: 'EB2244FB-98F7-4B4D-98B4-47DE46164F53',
        detailFileModels: null,
      },
    }
  },
  watch: {
    libraryStore_state_resetQuillContent() {
      if (this.libraryStore_state_resetQuillContent.reset) {
        if (this.quillId === this.libraryStore_state_resetQuillContent.idQuill) {
          this.modelSend.content = ''
        }
      }
    },
    dDataRoot: {
      deep: true,
      handler(val) {
        this.func_dataRoot_change(val)
      },
    },
  },
  mounted() {
    this.func_dataRoot_change(this.dDataRoot)
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_SetBreadCom']),
    imageHandler() {
      this.libraryStore_action_SetBreadCom([
        {
          id: 0,
          idFolder: '0',
          name: 'Thư mục gốc',
        },
      ])
      this.$bvModal.show('idLibraryModel')
    },
    // handleInsertHtml(val) {
    //   const { quill } = this.$refs[this.commonRef] // lấy ra object quill theo ref - gỉa sử ở đây có nhiều quill editor trong một component
    //   const caretPosition = quill.getSelection(true)
    //   quill.insertEmbed(caretPosition.index, 'image', val)
    // },
    handleCloseLibraryModal(data) {
      const { quill } = this.$refs[this.commonRef] // lấy ra object quill theo ref - gỉa sử ở đây có nhiều quill editor trong một component
      const caretPosition = quill.getSelection(true)
      quill.insertEmbed(caretPosition.index, 'image', data.imagePath)
    },
    handleInput() {
      this.$emit('quillChangeData', this.content)
    },
    handleChange() {
    },
    func_dataRoot_change(val) {
      if (this.dDataRoot.groupId === this.groupId && this.dDataRoot.groupFatherId === this.groupFatherId) {
        this.modelSend = this.dDataRoot
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = null
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.ql-editor {
  min-height: 250px;
}
</style>
