<template>
  <b-modal
    :id="Nv3ContentId"
    :hide-footer="true"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="xl"
    title="Cập nhật nội dung"
    @hidden="resetModal"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          class="font-weight-600"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          - Nội dung <span
            :hidden="Nv3ContentRequired"
            class="nRequired"
          >(*)</span>
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="mb-1"
        >
          <quill-editor
            :id="Nv3ContentQuillId"
            :ref="commonRef"
            v-model="detailFileModels.fileContent"
            :options="editorOption"
          />
          <library-modal
            :idLibraryModel="Nv3ContentLibraryId"
            @handleCloseLibraryModal="handleCloseLibraryModal"
          />
        </b-col>
        <b-col
          class="text-center"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <hr />
        </b-col>
        <b-col
          class="text-center"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            :disabled="loading"
            variant="outline-primary"
            @click="handler_click(3)"
          >
            <feather-icon
              class="mr-50"
              icon="RotateCcwIcon"
            />
            Quay về
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="loading"
            class="ml-1"
            size="sm"
            variant="danger"
            @click="handler_click(4)"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></div>
            <span v-if="loading"> Đang xử lý dữ liệu ...</span>
            <span v-else>Lưu dữ liệu</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import NQuillEditor from '@/views/ndev-component/NQuillEditor.vue'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'

export default {
  components: {
    LibraryModal,
    quillEditor,
    NQuillEditor,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    rootData: {
      type: Array,
      default: _ => {
      },
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentId: {
      type: String,
      default: 'Nv3ContentId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentQuillId: {
      type: String,
      default: 'Nv3ContentId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentPlace: {
      type: String,
      default: 'Nv3ContentId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentLibraryId: {
      type: String,
      default: 'Nv3ContentId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentType: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
      ['image'],
      ['video'],
    ]
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
            },
          },
        },
        theme: 'snow',
      },
      content: '',
      commonRef: {
        type: Object,
        default: _ => {
        },
      },
      Nv3ContentModal: {
        id: '',
        name: '',
        content: '',
        position: 0,
        groupId: '',
        groupFatherId: '',
        typeId: '',
        detailFileModels: null,
      },
      detailFileModels: {
        id: '',
        carCatalogDetailId: '',
        fileId: '',
        filePath: '',
        fileName: '',
        name: '',
        fileType: '',
        fileContent: '',
        filePosition: 0,
      },
      isDeleteImage: false,
      isSaveImage: false,
      loading: false,
      filePath: '',
    }
  },
  watch: {
    rootData: {
      deep: true,
      handler(val) {
        // eslint-disable-next-line prefer-destructuring
        this.Nv3ContentModal = val
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = val.model[0].detailFileModels[0]
      },
    },
  },
  // mounted() {
  //   this.func_dataRoot_change(this.rootData)
  // },
  methods: {
    handler_click(type) {
      switch (type) {
        case 1: {
          break
        }
        case 2: {
          break
        }
        case 3: {
          // Quay về
          this.$bvModal.hide(this.Nv3ContentId)
          break
        }
        case 4: {
          // Lưu dữ liệu
          this.loading = true
          this.Nv3ContentModal.model[0].detailFileModels = []
          this.Nv3ContentModal.model[0].detailFileModels.push(this.detailFileModels)
          this.$emit('handClick', this.Nv3ContentType, this.Nv3ContentModal)
          break
        }
        default: {
          break
        }
      }
    },
    ...mapActions('libraryStore', ['libraryStore_action_SetBreadCom']),
    imageHandler() {
      debugger
      this.libraryStore_action_SetBreadCom([
        {
          id: 0,
          idFolder: '0',
          name: 'Thư mục gốc',
        },
      ])
      this.$bvModal.show(this.Nv3ContentLibraryId)
    },
    handleCloseLibraryModal(data) {
      const { quill } = this.$refs[this.commonRef] // lấy ra object quill theo ref - gỉa sử ở đây có nhiều quill editor trong một component
      const caretPosition = quill.getSelection(true)
      quill.insertEmbed(caretPosition.index, 'image', data.imagePath)
    },
    resetModal() {
      this.loading = false
      this.detailFileModels.FileContent = ''
    },
  },
}
</script>
