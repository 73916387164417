<template>
  <div>
    <b-modal
      :id="idLibraryModel"
      :hide-footer="true"
      :hide-header="false"
      centered
      no-close-on-backdrop
      size="xl"
      title="THƯ VIỆN CỦA TÔI"
      @hidden="resetState"
    >
      <div>
        <b-row>
          <b-col
            lg="12"
            md="12"
            sm="12"
            style="margin-bottom: 5px"
            xs="12"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="danger"
              @click="handleCloseLibraryModal()"
            >
              Quay về
            </b-button>
            <b-button
              v-for="items in libraryStore_state_BreadCom"
              :key="items.id"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="ml-1"
              size="sm"
              variant="primary"
              @click="handleBreadCom(items)"
            >
              {{ items.name }}
            </b-button>
          </b-col>
          <b-col
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <library-paging-folder
              :data-bread-com="dataBreadCom"
              @handleCallData="handleCallData"
            />
            <library-paging-file
              :data-bread-com="dataBreadCom"
              class="mt-1"
              @handleCloseLibraryModal="handleCloseLibraryModal"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCardText, BCol, BDropdown, BDropdownItem, BForm, BFormGroup, BRow,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import LibraryFolder from '@/views/ndev-component/library/event/LibraryFolderEvent.vue'
import ServerFileConstantsApi from '@/views/ndev-component/constants/ServerFileConstantsApi'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import LibraryPagingFolder from '@/views/ndev-component/library/paging/library-paging-folder.vue'
import LibraryPagingFile from '@/views/ndev-component/library/paging/library-paging-file.vue'
import LibraryDetail from '@/views/ndev-component/library/paging/library-detail.vue'

export default {
  components: {
    LibraryDetail,
    LibraryPagingFile,
    LibraryPagingFolder,
    LibraryFolder,
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    ServerFileConstantsApi,
  },
  directives: {
    Ripple,
  },
  mixins: [NCoreHelper],
  props: {
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    idLibraryModel: {
      type: String,
      default: 'modalCreate',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_BreadCom']),
  },
  watch: {
    libraryStore_state_BreadCom() {
      this.dataBreadCom = this.libraryStore_state_BreadCom
    },
    idLibraryModel(val) {
      this.eventId = val
    },
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      dataFolderDetail: [], // Dữ liệu chi tiết
      dataRight: [], // Dữ liệu chi tiết
      modalType: '',
      modalId: '',
      mainKey: '',
      isRightActive: false,
      dataBreadCom: [],
      eventId: '',
    }
  },
  methods: {
    ...mapActions('libraryStore', [
      'libraryStore_action_SetBreadCom',
      'libraryStore_action_SetBreadCom',
      'libraryStore_action_SetData_ChildFolder',
      'libraryStore_action_SetData_ChildFile',
    ]),
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('accept', this.parentId, this.$refs.rule)
      this.parentId = ''
    },
    cancel() {
      this.$bvModal.hide(this.eventId)
      this.parentId = ''
    },
    resetState() {

    },
    // ==============Handler====================================
    async handleCallData(data) {
      this.$store.commit('libraryStore_setDataDetail', data)
    },
    handleCloseLibraryModal(val) {
      this.$bvModal.hide(this.eventId)
      this.$emit('handleCloseLibraryModal', val)
    },
    handleBreadCom(data) {
      const arr = []
      for (let i = 0; i < this.libraryStore_state_BreadCom.length; i++) {
        if (this.libraryStore_state_BreadCom[i].id <= data.id) {
          arr.push({
            id: this.libraryStore_state_BreadCom[i].id,
            name: this.libraryStore_state_BreadCom[i].name,
            idFolder: this.libraryStore_state_BreadCom[i].idFolder,
          })
        }
      }
      this.libraryStore_action_SetBreadCom(arr)
      this.libraryStore_action_SetData_ChildFolder({
        reload: true,
        idParent: data.idFolder,
      })
      this.libraryStore_action_SetData_ChildFile({
        reload: true,
        idParent: data.idFolder,
      })
    },
  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }

  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }

  .action {
    margin-top: 25px;
  }

  .description {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}

@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn-left {
        margin-right: 0px !important;
      }

      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
