<template>
  <b-row style="padding-left: 10px;padding-bottom: 10px;padding-right: 10px;">
    <b-col
      class="mt-1"
      lg="12"
      md="12"
      sm="12"
      xs="12"
    >
      <quill-editor
        :id="'quillEditor'"
        :ref="commonRef"
        v-model="exportHtmlContent"
        :options="editorOption"
      />
      <library-modal
        :idLibraryModel="'exportHtmlLibraryId'"
        @handleCloseLibraryModal="submitLibraryEvent"
      />
    </b-col>
  </b-row>
</template>

<script>
import VSelect from 'vue-select'
import { formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge, BDropdown, BDropdownItem, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import { ImageDrop } from 'quill-image-drop-module'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import NFormStatus from '@/views/ndev-component/NFormStatus.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'
import CCAddNew from '@/views/car-manager/car-catalog/pages/components/CCAddNew.vue'
import NV3Image from '@/views/ndev-component/componentV3/NV3Image.vue'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import NV3Title from '@/views/ndev-component/componentV3/NV3Title.vue'
import NV3Content from '@/views/ndev-component/componentV3/NV3Content.vue'
import NV3RowData from '@/views/ndev-component/componentV3/NV3RowData.vue'
import NV3MultiSmallImage from '@/views/ndev-component/componentV3/NV3MultiSmallImage.vue'
import DesignEvent from '@/views/car-manager/car-catalog/pages/components/Design/DesignEvent.vue'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'
import SaoChepThongSoKyThuat from '@/views/car-manager/car-catalog/pages/components/HtmlExport/SaoChepThongSoKyThuat.vue'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    SaoChepThongSoKyThuat,
    VSelect,
    quillEditor,
    LibraryModal,
    DesignEvent,
    NV3MultiSmallImage,
    NV3RowData,
    NV3Content,
    NV3Title,
    NButtonHeader,
    NV3Image,
    CCAddNew,
    NFormStatus,
    ModalDeleteNoParam,
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonComponent,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    contentNews: {
      type: String,
      default: '',
    },
  },
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
      ['image'],
      ['video'],
    ]
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
            },
          },
          imageDrop: true,
          imageResize: true,
        },
        theme: 'snow',
      },
      commonRef: {
        type: Object,
        default: _ => {
        },
      },
      currentPage: 1,
      exportHtmlContent: '',
      nv3ModalDeleteId: 'nv3ModalDeleteId',
      dataUpdate: {
        id: '',
        carCategoryId: this.$route.params.id,
        group: localStorage.getItem('htmGroup'),
        value: '',
      },
      groupCombobox: [
        {
          id: 1,
          name: 'Tổng quan',
        },
        {
          id: 2,
          name: 'Thiết kế',
        },
        {
          id: 3,
          name: 'Vận hành',
        },
        {
          id: 4,
          name: 'Intelli',
        },
      ],
    }
  },
  watch: {
    contentNews(val) {
      this.exportHtmlContent = val
    },
  },
  mounted() {
    this.dataUpdate.group = 1
    this.callData()
  },
  created() {
    // this.fetchDataList()
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_SetBreadCom']),
    handerChangeGroup() {
      this.callData()
    },
    async callData() {
      // const data = {
      //   id: this.$route.params.id,
      //   group: this.dataUpdate.group,
      // }
      // const iResult = await NCoreHelper.excuteGET(this, ConstantsApi.GET_BY_ID_CAR_HTML, data)
      // this.dataUpdate.id = iResult.id
      // this.dataUpdate.value = iResult.value
      // this.exportHtmlContent = iResult.value
    },
    async handlerSubmitPage(type) {
      switch (type) {
        case 1: {
          break
        }
        case 2: {
          this.dataUpdate.value = this.exportHtmlContent
          const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_CAR_HTML, this.dataUpdate, 'Cập nhật thành công')
          break
        }
        case 3: {
          this.$bvModal.show('SaoChepThongSoKyThuat')
          break
        }
        default: {
          break
        }
      }
    },
    imageHandler() {
      this.libraryStore_action_SetBreadCom([
        {
          id: 0,
          idFolder: '0',
          name: 'Thư mục gốc',
        },
      ])
      this.$bvModal.show('exportHtmlLibraryId')
    },
    submitLibraryEvent(data) {
      if (data !== undefined) {
        const { quill } = this.$refs[this.commonRef] // lấy ra object quill theo ref - gỉa sử ở đây có nhiều quill editor trong một component
        const caretPosition = quill.getSelection(true)
        quill.insertEmbed(caretPosition.index, 'image', data.imagePath)
      }
      this.$bvModal.hide('exportHtmlLibraryId')
    },
  },
}
</script>
