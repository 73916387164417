<template>
  <div>
    <b-badge
      v-if="statusId === 1"
      variant="light-dark"
    >
      <span>{{ statusName }}</span>
    </b-badge>
    <b-badge
      v-else-if="statusId === 2"
      variant="light-info"
    >
      <span>{{ statusName }}</span>
    </b-badge>
    <b-badge
      v-else-if="statusId === 3"
      variant="light-success"
    >
      <span>{{ statusName }}</span>
    </b-badge>
  </div>
</template>
<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    statusId: {
      type: Number,
      default: 0,
    },
    statusName: {
      type: String,
      default: '',
    },
    // statusColorSet: {
    //   type: String,
    //   default: '',
    // },
  },
  mounted() {
  },
  methods: {
    // callStatus() {
    //   switch (this.statusId) {
    //     case 1: {
    //       // Lock
    //       this.statusColorSet = 'light-dark'
    //       break
    //     }
    //     case 2: {
    //       // Pending
    //       this.statusColorSet = 'light-info'
    //       break
    //     }
    //     case 3: {
    //       // Active
    //       this.statusColorSet = 'light-success'
    //       break
    //     }
    //     default: {
    //       this.statusColorSet = ''
    //     }
    //   }
    // },
  },
}
</script>
