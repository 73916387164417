<template>
  <div>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="nmt-10px"
    >
      <img
        class="img-responsive"
        style="width: 100%!important;"
        :src="(avatarLink)"
        alt=""
      >
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  VBTooltip,
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    FeatherIcon,
    ButtonComponent,
    BButton,
    VBTooltip,
    Search,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      avatarLink: '',
    }
  },
  watch: {
    dDataRoot: {
      deep: true,
      handler(val) {
        this.func_dataRoot_change(val)
      },
    },
  },
  mounted() {
    this.func_dataRoot_change(this.dDataRoot)
  },
  methods: {
    func_dataRoot_change(val) {
      if (val !== null) {
        this.avatarLink = val === undefined ? '' : NCoreHelper.GetThumbnail(false, val.fileExtension, val.filePath, '64x64', this.$isServer, NCoreHelper.GetIsImageLocal(val.fileType))
      } else {
        this.avatarLink = ''
      }
    },
  },
}
</script>
