<template>
  <b-modal
    :id="CCAddNew_id"
    size="sm"
    title="Chọn loại dữ liệu"
    centered
    :hide-footer="true"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          v-for="items in ccAdnewModal"
          :key="items.id"
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="nmt-5px form-control"
            @click="handler_select_button(items)"
          >
            {{ items.name }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import HelperConstantsApi from '@/views/ndev-component/constants/HelperConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    CCAddNew_id: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCAddNew_Group: {
      type: Number,
      default: 0,
    },
    rootData: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      ccAdnewModal: [],
    }
  },
  watch: {
    rootData: {
      deep: true,
      handler(val) {
        this.ccAdnewModal = val
      },
    },
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handler_select_button(data) {
      this.$emit('handleClick', data)
    },
    async fetchData() {
      const sendData = {
        groupId: this.CCAddNew_Group,
      }
      const iResult = await NCoreHelper.excuteGET(this, HelperConstantsApi.CONFIG_SYSTEM_COMBOBOX, sendData)
      this.rootData = iResult
    },
  },
}
</script>
