<template>
  <div
    id="library-detail"
    class="page-container-table"
  >
    <div>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="file_title"
        >
          <b-img
            :src="filePath"
            fluid
            :alt="libraryStore_state_DataDetail.name"
          />
        </b-col>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="file_detail_title"
        >
          {{ libraryStore_state_DataDetail.name }}
        </b-col>
      </b-row>
      <b-row
        style="padding: 0 10px 0 10px"
        class="mt-1"
        :hidden="detailHidden"
      >
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleViewDetail()"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Xem chi tiết
          </b-button>
        </b-col>
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
        </b-col>
      </b-row>
      <b-row
        style="padding: 0 10px 0 10px;"
        :hidden="detailHidden"
      >
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleOpenUpdate(1)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Cập nhật
          </b-button>
        </b-col>
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleOpenDelete(1)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Xóa
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="padding: 0 10px 0 10px"
        class="mt-1"
        :hidden="detailFile"
      >
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleViewDetail()"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Chọn ảnh
          </b-button>
        </b-col>
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
        </b-col>
      </b-row>
      <b-row
        style="padding: 0 10px 0 10px;"
        :hidden="detailFile"
      >
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleOpenUpdate(1)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Đổi tên
          </b-button>
        </b-col>
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleOpenDelete(1)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Xóa
          </b-button>
        </b-col>
        <b-col
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            size="sm"
            class="form-control nFolder_Title mt-1"
            variant="outline-primary"
            @click="handleOpenDelete(1)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Tải về
          </b-button>
        </b-col>
      </b-row>
    </div>

    <library-folder-event
      idModalLibraryFolder="idModalFolderUpdate"
      type="edit"
      :dataFolderDetail="dataFolderDetail"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      idModalDeleteNoParam="idModalDelete"
      :contentTile="modalContent"
      @accept="handleRunDelete()"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { GetSmallImage } from '@core/utils/filter'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import LibraryFolderEvent from '@/views/ndev-component/library/event/LibraryFolderEvent.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import ServerFileConstantsApi from '@/views/ndev-component/constants/ServerFileConstantsApi'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'

export default {
  components: {
    ModalDeleteNoParam,
    LibraryFolderEvent,
    NButtonHeader,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    ButtonComponent,
    ConfirmModal,
    ButtonAllHeader,
    UserAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },
  filters: {},
  data() {
    return {
      deleteIds: [], // ID xóa
      isActive: false,
      dataFolderDetail: [], // dữ liệu chi tiết folder
      filePath: '',
      detailHidden: true,
      detailFile: true,
    }
  },
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_DataDetail', 'libraryStore_state_reloadFolder', 'libraryStore_state_reloadFile']),
  },
  watch: {
    libraryStore_state_DataDetail() {
      this.filePath = this.getThumbnail(this.libraryStore_state_DataDetail.filePath)
      if (this.libraryStore_state_DataDetail.isFolder) {
        this.detailHidden = false
        this.detailFile = true
      } else {
        this.detailFile = false
        this.detailHidden = true
      }
    },
    libraryStore_state_reloadFolder() {
      this.$bvModal.hide('idModalFolderUpdate')
      this.$bvModal.hide('idModalDelete')
      this.detailHidden = true
    },
    libraryStore_state_BreadCom() {
    },
  },
  mounted() {
    this.detailHidden = true
    this.detailFile = true
  },
  created() {
  },
  methods: {
    ...mapActions('libraryStore', [
      'libraryStore_action_updateFolder',
      'libraryStore_action_reloadFolder',
      'libraryStore_action_reloadFile',
      'libraryStore_action_SetBreadCom',
      'libraryStore_action_SetData_ChildFolder',
    ]),
    getThumbnail() {
      return NCoreHelper.GetThumbnail(
        this.libraryStore_state_DataDetail.isFolder,
        this.libraryStore_state_DataDetail.fileExtension,
        this.libraryStore_state_DataDetail.filePath,
        '128x128',
        this.$serverfile,
        NCoreHelper.GetIsImageLocal(this.libraryStore_state_DataDetail.fileType),
      )
    },
    async handleOpenUpdate(isFolder) {
      if (isFolder) {
        this.dataFolderDetail = await NCoreHelper.excuteGET(this, ServerFileConstantsApi.SERVER_FILE_GET_BY_ID, { id: this.libraryStore_state_DataDetail.id })
        this.$bvModal.show('idModalFolderUpdate')
      }
    },
    handleUpdateFolder() {
      this.$bvModal.hide('idModalFolderUpdate')
    },
    handleOpenDelete() {
      this.deleteIds = [this.libraryStore_state_DataDetail.id]
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show('idModalDelete')
    },
    async handleRunDelete() {
      const iMessage = this.libraryStore_state_DataDetail.isFolder
        ? 'Xóa thư mục thành công'
        : 'Xóa file thành công'
      const { dataSend } = await NCoreHelper.excuteDELETE(this, ServerFileConstantsApi.SERVER_FILE_DELETE, { model: JSON.stringify(this.deleteIds) }, iMessage)
      if (this.libraryStore_state_DataDetail.isFolder) {
        this.libraryStore_action_reloadFolder(true)
      } else {
        this.libraryStore_action_reloadFile(true)
      }
      this.$bvModal.hide('idModalDelete')
    },
    handleViewDetail() {
      const breadCom = this.libraryStore_state_DataDetail.breadcrumb
      const dBreadComNew = {
        // eslint-disable-next-line no-use-before-define
        id: Math.max(...breadCom.map(o => o.id)) + 1,
        name: this.libraryStore_state_DataDetail.name,
        idFolder: this.libraryStore_state_DataDetail.id,
      }
      breadCom.push(dBreadComNew)
      // ==Cập nhật breadCum
      this.libraryStore_action_SetBreadCom(breadCom)
      // ==Reload Folder - File theo ID vừa chọn ====
      const sendChildFolder = {
        reload: true,
        idParent: this.libraryStore_state_DataDetail.id,
      }
      this.libraryStore_action_SetData_ChildFolder(sendChildFolder)
      // ===Cập nhật lại các phím chức năng=======
      this.detailHidden = true
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#library-detail {
  height: 100%;
}

.file_detail_title {
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
  color: red;
  font-size: 15px;
}
</style>
