<!-- +++++++++++==Thẻ và import==++++++++++++page-container-table
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
 <button-all-header
        :contentBtnAdd="'Thêm'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteMore"
        @clickDowloadSample="dowloadFile"
        @clickExportExcel="downloadFileAll"
        @importFile="fileChange($event)"
        @clickAdd="$router.push('position-job/add')"
        @search="search($event)"
      />
Hướng dẫn truyền:
    :contentBtnAdd=Nội dung nút thêm mới
     :arrayExecl : Truyền mảng các trường execel
     :showBtnMultiDelete : để show hay ẩn nút delete
     @clickDelete : kích chuột xảy ra delete
     @clickDowloadSample: Tải tập tin mẫu
     @clickExportExcel: Xuất file excel cho người dùng
     @importFile: Dự liệu nhận về là dữ liệu đã chuyển từ excel thành mảng JS
     @search : Nhận lại giá trị search
     @clickAdd : Xảy ra sự kiện click khi nhấn nút tạo
Thuộc tính truyền ẩn đi : truyền false là nó ẩn trường đó đi
      hideDelete : nút xóa
      hideImportFile : nút import file xử lý
      hideExportFile : nút tải file về
      hideDowload : nút dowload file mẫu
      hideSearch : ô tìm kiếm
      hideAdd : button thêm

-->
<template>
  <div class="d-flex justify-content-between">
    <b-row style="width: 100%">
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="NButtonHeader"
      >
        <div id="btn-all-header">
          <!-- -------------Delete------------- -->
          <!--      <button-component-->
          <!--        v-if="hideDelete"-->
          <!--        v-b-tooltip.hover.top="'Xóa nhiều'"-->
          <!--        class="ml-2 button-icon"-->
          <!--        content-btn=""-->
          <!--        variant="danger"-->
          <!--        size="sm"-->
          <!--        icon-btn="Trash2Icon"-->
          <!--        :disabled="!showBtnMultiDelete"-->
          <!--        @click="$emit('clickDelete')"-->
          <!--      />-->
          <!--    </div>-->
          <div class="header-action">
            <search
              v-if="hideSearch"
              class="search-input size_search"
              size="sm"
              @change="search"
            />
            <button-component
              v-if="hideAdd"
              size="sm"
              class="ml-1"
              :content-btn="contentBtnAdd"
              variant="primary"
              icon-btn="PlusIcon"
              @click="$emit('clickAdd')"
            />
            <button-component
              :hidden="hideMultiAdd"
              size="sm"
              :content-btn="contentBtnMultiAdd"
              variant="danger"
              icon-btn="PlusIcon"
              @click="$emit('clickMultiAdd')"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  VBTooltip,
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'

export default {
  components: {
    FeatherIcon,
    ButtonComponent,
    BButton,
    VBTooltip,
    Search,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    showBtnMultiDelete: {
      type: Boolean,
      default: false,
    },
    contentBtnAdd: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: 'Thêm',
    },
    contentBtnMultiAdd: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: 'Thêm',
    },
    hideSearch: {
      type: Boolean,
      default: true,
    },
    hideAdd: {
      type: Boolean,
      default: true,
    },
    hideMultiAdd: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    // Tìm kiếm
    search(val) {
      this.$emit('search', val)
    },
  },
}
</script>

<style lang="scss">
.NButtonHeader {
  float: right!important;
}
.NButtonHeader .size_search .form-control{
  height: 30px !important;
}

#btn-all-header {
  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;

    label {
      margin-bottom: unset !important;
    }
  }
}

.search-input {
  width: 400px;
}

</style>
