<template>
  <b-modal
    :id="Nv3MultiSmallImageId"
    :hide-footer="true"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="md"
    title="Cập nhật nhiều ảnh nhỏ"
    @hidden="resetModal"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          class="mb-1 text-left"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          Ảnh 01
        </b-col>
        <b-col
          class="mb-1 text-left"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          Ảnh 01
        </b-col>
        <b-col
          class="font-weight-600 mb-1 text-left"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <library-modal
            :id-library-model="Nv3MultiSmallImageLibraryId"
            @handleCloseLibraryModal="handleCloseLibraryModal"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
            @click="handler_click(1)"
          >
            <feather-icon
              icon="CloudIcon"
            />
            Chọn ảnh
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="ml-1"
            size="sm"
            variant="dark"
            @click="handler_click(3)"
          >
            <feather-icon
              icon="CloudIcon"
            />
            Quay về
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            size="sm"
            class="ml-1"
            @click="handler_click(4)"
          >
            <feather-icon
              icon="CloudIcon"
            />
            Lưu lựa chọn
          </b-button>
        </b-col>
      </b-row>
      <b-row style="padding: 0 10px 0 10px">
        <b-col
          v-for="items in dataRows"
          :key="items.fileId"
          lg="3"
          md="4"
          sm="6"
          xl="2"
          xs="12"
          @click="handleCallData(items)"
        >
          <b-row class="detail_image">
            <b-col
              lg="12"
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-img-lazy
                :alt="items.fileName"
                :src="getThumbnail(items)"
              />
            </b-col>
            <b-col
              class="file_title"
              lg="12"
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              {{ items.fileName }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <n-view-image
        :data-send="detailFileModels"
        :id-modal="Nv3MultiSmallImageLibraryViewId"
        @handlerDeleteImage="handlerDeleteImage"
      />
      <!-- Modal xóa-->
      <modal-delete-no-param
        :content-tile="'Bạn chắc chắn muốn xóa hình ảnh này ?'"
        id-modal-delete-no-param="idModalQuestion"
        @accept="handleButon(3,null)"
      />
    </b-form>
  </b-modal>
</template>
<script>
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NViewImage from '@/views/ndev-component/news-data-manager/NViewImage.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'

export default {
  components: {
    ModalDeleteNoParam,
    NViewImage,
    LibraryModal,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    rootData: {
      type: Array,
      default: _ => {
      },
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3MultiSmallImageLibraryId: {
      type: String,
      default: 'Nv3MultiSmallImageLibraryId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3MultiSmallImageId: {
      type: String,
      default: 'Nv3MultiSmallImageId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3MultiSmallImageType: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3MultiSmallImageLibraryViewId: {
      type: String,
      default: 'Nv3MultiSmallImageLibraryViewId',
    },
  },
  data() {
    return {
      Nv3MultiSmallImageModal: {
        id: '',
        name: '',
        content: '',
        position: 0,
        groupId: '',
        groupFatherId: '',
        typeId: '',
        detailFileModels: this.detailFileModels,
      },
      detailFileModels: {
        id: '',
        carCatalogDetailId: '',
        fileId: '',
        filePath: '',
        fileName: '',
        name: '',
        fileType: '',
        fileContent: '',
        filePosition: 0,
      },
      isDeleteImage: false,
      isSaveImage: false,
      loading: false,
      filePath: '',
    }
  },
  watch: {
    rootData: {
      deep: true,
      handler(val) {
        // eslint-disable-next-line prefer-destructuring
        this.Nv3MultiSmallImageModal = val
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = val.model[0].detailFileModels[0]
        this.filePath = this.getThumbnail(false, val.fileType, val.filePath)
      },
    },
  },
  // mounted() {
  //   this.func_dataRoot_change(this.rootData)
  // },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handler_click(type) {
      switch (type) {
        case 1: {
          this.libraryStore_action_ResetBreadCom()
          this.$bvModal.show(this.Nv3MultiSmallImageLibraryId)
          break
        }
        case 2: {
          this.detailFileModels.filePath = ''
          this.detailFileModels.fileId = ''
          break
        }
        case 3: {
          // Quay về
          this.$bvModal.hide(this.Nv3MultiSmallImageId)
          break
        }
        case 4: {
          // Lưu dữ liệu
          this.loading = true
          this.Nv3MultiSmallImageModal.model[0].detailFileModels = []
          this.Nv3MultiSmallImageModal.model[0].detailFileModels.push(this.detailFileModels)
          this.$emit('handClick', this.Nv3MultiSmallImageType, this.Nv3MultiSmallImageModal)
          break
        }
        default: {
          break
        }
      }
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else if (this.dataRows === null || this.dataRows === undefined) {
        this.detailFileModels = {
          id: '',
          carCatalogDetailId: '',
          name: '',
          fileContent: '',
          filePosition: 1,
          fileName: val.name,
          fileId: val.id,
          filePath: val.filePath,
          fileType: val.fileType,
        }
        this.dataRows = []
        this.dataRows.push(this.detailFileModels)
      } else {
        this.detailFileModels = {
          id: '',
          carCatalogDetailId: '',
          name: '',
          fileContent: '',
          fileName: val.name,
          fileId: val.id,
          filePath: val.filePath,
          fileType: val.fileType,
          filePosition: this.dataRows.length > 0 ? Math.max(...this.dataRows.map(o => o.filePosition)) + 1 : 1,
        }
        this.dataRows.push(this.detailFileModels)
      }
      this.Nv3MultiSmallImageModal.detailFileModels = []
      this.Nv3MultiSmallImageModal.detailFileModels = this.dataRows
      this.$bvModal.hide(this.idLibraryFromNAnhDaiDien)
      this.$bvModal.hide(this.Nv3MultiSmallImageLibraryId)
    },
    // func_dataRoot_change(val) {
    //   this.Nv3MultiSmallImageModel =
    //   this.filePath = this.getThumbnail()
    // },
    getThumbnail(isChooseImage) {
      this.isDeleteImage = false
      if (isChooseImage === true) {
        if (this.detailFileModels.fileType === '2') {
          return this.detailFileModels.filePath
        }
        return `${this.$serverfile}${this.detailFileModels.filePath}`
      }

      if (this.detailFileModels.fileType === '2') {
        return this.detailFileModels.filePath
      }
      return `${this.$serverfile}${this.detailFileModels.filePath}`
    },
    resetModal() {
      this.loading = false
      this.filePath = ''
    },
    handleCallData(data) {
      this.detailFileModels = data
      this.$bvModal.show(this.Nv3MultiSmallImageLibraryViewId)
    },
  },
}
</script>
