<template>
  <div
    id="library-paging-folder"
    class="page-container-table"
  >
    <!--phần header-->
    <n-button-header
      :content-btn-add="'Upload file'"
      :content-btn-multi-add="'Upload nhiều file'"
      :hide-multi-add="false"
      @clickAdd="openModal(1)"
      @clickMultiAdd="openModal(3)"
      @search="search($event)"
    />

    <b-row style="padding: 0 10px 0 10px">
      <b-col
        v-for="items in dataRows"
        :key="items.id"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        @click="handleCallData(items)"
      >
        <b-row class="detail_image">
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <b-img-lazy
              :src="getImage(items)"
              :alt="items.name"
            />
          </b-col>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="file_title"
          >
            {{ items.name }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- pagination -->
    <my-pagination
      :total-items="totalRecord"
      :current-page="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <library-file-event
      :id-modal-library-folder="modalId"
      :type="library_file_event_type"
      :parent-id="parentId"
      :data-folder-detail="dataDetail"
      @handleUpdateFolder="handleCloseFolderFunc"
    />

    <library-file-func
      idModalLibraryFolderFunc="idModalLibraryFileFunc"
      :data-send="dataSendFolderFunc"
      @handleCloseFolderFunc="handleCloseFolderFunc"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      idModalDeleteNoParam="idModalDelete"
      contentTile="Bạn chắc chắn muốn xóa"
      @accept="handleCloseFolderFunc(200)"
    />
  </div>
</template>

<script>
import {
  BBadge, BCol, BDropdown, BDropdownItem, BMedia, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import ServerFileConstantsApi from '@/views/ndev-component/constants/ServerFileConstantsApi'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import LibraryFileEvent from '@/views/ndev-component/library/event/LibraryFileEvent.vue'
import LibraryFileFunc from '@/views/ndev-component/library/Func/LibraryFileFunc.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'

export default {
  components: {
    LibraryFileFunc,
    LibraryFileEvent,
    ModalDeleteNoParam,
    NButtonHeader,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    ButtonComponent,
    ConfirmModal,
    ButtonAllHeader,
    UserAvatar,
  },
  directives: {
    Ripple,
  },
  filters: {},
  props: {
    dataBreadCom: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        isFolder: false,
        parentId: '0',
      },
      deleteIds: [], // ID xóa
      isActive: false,
      userId: '',
      dataRows: [], // dữ liệu bảng
      totalRecord: 10, // tổng số trang
      dataDetail: [], // Dữ liệu chi tiết
      modalId: 'id-library-file-event',
      parentId: '',
      dataSendFolderFunc: Object,
      library_file_event_type: '',
    }
  },
  computed: {
    ...mapState('libraryStore', [
      // 'libraryStore_state_DataDetail',
      // 'libraryStore_state_updateFolder',
      'libraryStore_state_ChildFile',
      // 'libraryStore_state_ChildFile',
    ]),
  },
  watch: {
    // async libraryStore_state_updateFolder() {
    //   if (this.libraryStore_state_updateFolder) {
    //     const { dataSend } = await NCoreHelper.excuteGET(this, ServerFileConstantsApi.SERVER_FILE_GET_BY_ID, { id: this.libraryStore_state_DataDetail.id })
    //     this.dataDetail = dataSend
    //     this.openModal(2)
    //     this.libraryStore_action_updateFolder(false)
    //   }
    // },
    async libraryStore_state_ChildFile() {
      if (this.libraryStore_state_ChildFile.reload) {
        this.urlQuery.parentId = this.libraryStore_state_ChildFile.idParent
        await this.fetchDataList()
        this.libraryStore_action_SetData_ChildFile({
          reload: false,
          idParent: '0',
        })
      }
    },
    // async libraryStore_state_ChildFolder() {
    //   if (this.libraryStore_state_ChildFolder.reload) {
    //     this.urlQuery.idParent = this.libraryStore_state_ChildFolder.idParent
    //     await this.fetchDataList()
    //   }
    // },
  },
  mounted() {
  },
  created() {
    this.fetchDataList()
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_SetData_ChildFile']),
    // =========================Call danh sách dữ liệu===========================
    async fetchDataList() {
      this.urlQuery.isFolder = false
      const iResult = await NCoreHelper.excuteGET(this, ServerFileConstantsApi.SERVER_FILE_PAGING, this.urlQuery)
      this.dataRows = iResult.pageLists
      this.totalRecord = iResult.totalRecord
    },
    // =========================Xử lý click chọn ====================================//
    openModal(type) {
      this.library_file_event_type = type === 1 ? 'add' : 'edit'
      this.parentId = this.dataBreadCom[this.dataBreadCom.length - 1].idFolder
      this.$bvModal.show(this.modalId)
    },
    handleCallData(data) {
      this.dataSendFolderFunc = data
      this.$bvModal.show('idModalLibraryFileFunc')
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        await this.fetchDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        await this.fetchDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataList()
    },
    // ---- Open model -----
    handleOpenModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
    },
    // ==========================Hàm xem chi tiết======================================
    getImage(data) {
      const iResult = NCoreHelper.GetThumbnail(false, data.fileExtension, data.filePath, '128x128', this.$serverfile, NCoreHelper.GetIsImageLocal(data.fileType))
      return iResult
    },
    async handleCloseFolderFunc(val, data) {
      switch (parseInt(val, 10)) {
        case 1: {
          // Xem chi tiết
          // Cập nhật thông tin call API
          this.urlQuery.parentId = data.id
          this.urlQuery.pageNumber = 1
          // Cập nhật breadCom
          const breadCom = this.dataBreadCom // this.libraryStore_state_DataDetail.breadcrumb
          const dBreadComNew = {
            // eslint-disable-next-line no-use-before-define
            id: Math.max(...breadCom.map(o => o.id)) + 1,
            name: data.name,
            idFolder: data.id,
          }
          breadCom.push(dBreadComNew)
          // ==Cập nhật breadCum
          this.libraryStore_action_SetBreadCom(breadCom)
          // Gửi yêu cầu để cập nhật lại danh sách file - hình ảnh theo ID vừa chọn
          const sendChildFile = {
            reload: true,
            idParent: data.id,
          }
          this.libraryStore_action_SetData_ChildFile(sendChildFile)
          // call Api
          await this.fetchDataList(this.urlQuery)
          break
        }
        case 2: {
          // Cập nhật
          this.dataDetail = data
          this.openModal(2)
          break
        }
        case 3: {
          // Xóa
          this.$bvModal.show('idModalDelete')
          break
        }
        case 200: {
          const dataDelete = []
          dataDelete.push(data.id)
          const { dataSend } = await NCoreHelper.excuteDELETE(this, ServerFileConstantsApi.SERVER_FILE_DELETE, { model: JSON.stringify(dataDelete) }, 'Xóa thư mục thành công')
          this.$bvModal.hide('idModalDelete')
          await this.fetchDataList(this.urlQuery)
          break
        }
        case 500: {
          this.$bvModal.hide('idModalLibraryFileFunc')
          // => người dùng chọn hình ảnh => emit trả về quillEditor
          this.$emit('handleCloseLibraryModal', data)
          break
        }
        default: {
          this.$bvModal.hide(this.modalId)
          await this.fetchDataList(this.urlQuery)
          break
        }
      }
      this.$bvModal.hide('idModalLibraryFolderFunc')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.detail_image:hover {
  cursor: pointer;
  font-weight: bold;
  color: blue;
}

.detail_image img {
  width: 100%;
  height: 128px;
  object-fit: cover
}

.file_title {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
