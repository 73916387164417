<template>
  <div>
    <b-modal
      :id="idModalDeleteNoParam"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="confirm-modal-delete">
        <div class="text-warning">
          <feather-icon :icon="icon" />
        </div>
        <div class="title">
          {{ contentTile }}
        </div>
        <div class="action">
          <b-button
            variant="primary"
            class="mr-1 btn-left"
            @click="accept"
          >
            Đồng ý
          </b-button>
          <b-button
            v-if="!isNotification"
            variant="outline-danger"
            @click="cancel"
          >
            Quay lại
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
  },
  props: {
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    idModalDeleteNoParam: {
      type: String,
      default: 'modalCreate',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    type: {
      type: String,
      default: '',
    },
    contentTile: {
      type: String,
      default: 'Bạn chắc chắn xóa',
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('accept', this.parentId, this.$refs.rule)
      this.parentId = ''
    },
    cancel() {
      this.$bvModal.hide(this.idModalDeleteNoParam)
      this.parentId = ''
    },
  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }

  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }

  .action {
    margin-top: 25px;
  }

  .description {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}

@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn-left {
        margin-right: 0px !important;
      }

      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
