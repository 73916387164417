<template>
  <b-modal
    :id="idModalLibraryFolderFunc"
    :hide-footer="true"
    :hide-header="true"
    cancel-title="Quay về"
    cancel-variant="outline-primary"
    centered
    size="sm"
    title="Chọn chức năng"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          class="text-center font-weight-600"
          md="12"
          sm="12"
          style="color: blue;text-transform: uppercase"
          xl="12"
          xs="12"
        >
          {{ dataSend.name }}
        </b-col>
        <b-col
          lg="6"
          ms="6"
          sm="6"
          xl="6"
          xs="12"
        >
          <b-img
            :alt="dataSend.name"
            :src="filePath"
            class="mt-1"
            fluid
            style="width: 100%;height: auto;object-fit: cover;"
          />
        </b-col>
        <b-col
          lg="6"
          ms="6"
          sm="6"
          xl="6"
          xs="12"
        >
          <b-row
            :hidden="library_file_func_hiddenButton"
          >
            <b-col
              class="text-left"
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                class="form-control nFolder_Title mt-1"
                size="sm"
                variant="outline-primary"
                @click="handleCloseFolderFunc(1)"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckSquareIcon"
                />
                Chọn ảnh
              </b-button>
            </b-col>
            <b-col
              class="text-left"
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                class="form-control nFolder_Title mt-1"
                disabled
                size="sm"
                variant="outline-primary"
                @click="handleCloseFolderFunc(2)"
              >
                <feather-icon
                  class="mr-50"
                  icon="Edit3Icon"
                />
                Đổi tên
              </b-button>
            </b-col>
            <b-col
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                class="form-control nFolder_Title mt-1"
                size="sm"
                variant="outline-primary"
                @click="handleCloseFolderFunc(200)"
              >
                <feather-icon
                  class="mr-50"
                  icon="RotateCcwIcon"
                />
                Xóa
              </b-button>
            </b-col>
            <b-col
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                class="form-control nFolder_Title mt-1"
                disabled
                size="sm"
                variant="outline-primary"
                @click="handleCloseFolderFunc(200)"
              >
                <feather-icon
                  class="mr-50"
                  icon="DownloadCloudIcon"
                />
                Tải về
              </b-button>
            </b-col>
            <b-col
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                class="form-control nFolder_Title mt-1"
                disabled
                size="sm"
                variant="outline-primary"
                @click="handleCloseFolderFunc(200)"
              >
                <feather-icon
                  class="mr-50"
                  icon="StopCircleIcon"
                />
                Mở ảnh/file
              </b-button>
            </b-col>
          </b-row>
          <b-row
            :hidden="library_file_func_hiddenSize"
            class="mt-1"
          >
            <b-col
              class="font-weight-600"
              lg="12"
              md="12"
              sm="12"
              xl="12"
              xs="12"
            >
              >> Chọn kích thước
            </b-col>
            <b-col
              v-for="items in dataSize"
              :key="items.id"
              class="mt-1"
              lg="6"
              md="6"
              sm="6"
              xl="6"
              xs="12"
            >
              <b-form-checkbox
                v-model="items.value"
                class="custom-control-success handleSize"
                unchecked-value="not_accepted"
                value="accepted"
                @change="handleSizeChange(items)"
              >
                {{ items.name }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          class="text-left"
          lg="12"
          ms="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-20"
            size="sm"
            variant="primary"
            @click="handleCloseModalFunc()"
          >
            <feather-icon
              class="mr-50"
              icon="LogOutIcon"
            />
            Quay về
          </b-button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :hidden="library_file_func_hiddenSave"
            class="ml-20"
            size="sm"
            variant="danger"
            @click="handleCloseFolderFunc(500)"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            Lưu lựa chọn
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    idModalLibraryFolderFunc: {
      type: String,
      default: '',
    },
    dataSend: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      dataSize: [
        {
          id: 128,
          name: 128,
          value: 'not_accepted',
        },
        {
          id: 350,
          name: 350,
          value: 'not_accepted',
        },
        {
          id: 650,
          name: 650,
          value: 'not_accepted',
        },
        {
          id: 1020,
          name: 1020,
          value: 'not_accepted',
        },
        {
          id: 1000,
          name: '1920',
          value: 'not_accepted',
        },
      ],
      filePath: '',
      library_file_func_hiddenButton: false,
      library_file_func_hiddenSize: true,
      library_file_func_hiddenSave: true,
    }
  },
  watch: {
    dataSend: {
      deep: true,
      handler(val) {
        if (val) {
          this.filePath = this.getThumbnail()
        }
      },
    },
  },
  created() {
    this.library_file_func_hiddenButton = false
    this.library_file_func_hiddenSize = true
    this.library_file_func_hiddenSave = true
  },
  methods: {
    async handleCloseFolderFunc(val) {
      if (val === 1) {
        // CHỌN ẢNH
        this.library_file_func_hiddenButton = true
        this.library_file_func_hiddenSize = false
        this.library_file_func_hiddenSave = false
      } else {
        switch (val) {
          case 500: {
            // Chọn size ảnh
            const iSelect = this.dataSize.find(x => x.value === 'accepted')
            const iWidth = iSelect === undefined ? '0px' : `${iSelect.id}px`
            const isLocal = NCoreHelper.GetIsImageLocal(this.dataSend.fileType)
            if (isLocal) {
              this.dataSend.imagePath = `${this.$serverfile}${this.dataSend.filePath}`
              // `<img class="img-responsive" style="width: ${iWidth}" src="${this.$serverfile}${this.dataSend.filePath}"  alt="${this.dataSend.name}"/>`
            } else {
              this.dataSend.imagePath = this.dataSend.filePath
              // `<img class="img-responsive" style="width: ${iWidth}" src="${this.dataSend.filePath}"  alt="${this.dataSend.name}"/>`
            }
            this.dataSend.closeLibraryModal = true
            break
          }
          default: {
            this.dataSend.imagePath = ''
            this.dataSend.closeLibraryModal = false
            break
          }
        }
        this.$emit('handleCloseFolderFunc', val, this.dataSend)
      }
    },
    handleCloseModalFunc() {
      if (this.library_file_func_hiddenSize === false && this.library_file_func_hiddenButton === true) {
        this.library_file_func_hiddenSize = true
        this.library_file_func_hiddenButton = false
        this.library_file_func_hiddenSave = true
      } else {
        this.$bvModal.hide(this.idModalLibraryFolderFunc)
      }
    },
    getThumbnail() {
      return NCoreHelper.GetThumbnail(
        false,
        this.dataSend.fileExtension,
        this.dataSend.filePath,
        '128x128',
        this.$serverfile,
        NCoreHelper.GetIsImageLocal(this.dataSend.fileType),
      )
    },
    handleSizeChange(data) {
      for (let i = 0; i < this.dataSize.length; i++) {
        if (this.dataSize[i].id !== data.id) {
          this.dataSize[i].value = 'not_accepted'
        }
      }
    },
    resetState() {
    },
  },
}
</script>
<style lang="scss">
.handleSize {
  cursor: pointer;
}

.handleSize:hover {
  cursor: pointer;
}
</style>
