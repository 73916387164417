<template>
  <b-modal
    :id="idModalLibraryFolder"
    size="sm"
    title="Thêm thư mục"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateFolder"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <label class="font-weight-600">1. Tên thư mục <span class="required">(*)</span></label>
            <b-form-input
              id="txtNameLibraryFolderEvent"
              v-model="queryBody.name"
              name="txtNameLibraryFolderEvent"
              placeholder="Nhập tên thư mục ..."
              autocomplete="off"
              autofocus
            />
            <small
              v-if="!$v.queryBody.name.required"
              class="text-danger"
            >
              Không được bỏ trống</small>
            <!--            <small-->
            <!--              v-if="!$v.queryBody.name.minLength"-->
            <!--              class="text-danger"-->
            <!--            >-->
            <!--              Tên có độ dài tối thiểu-->
            <!--              {{ $v.queryBody.name.$params.minLength.min }} ký tự.</small>-->
            <small
              v-if="!$v.queryBody.name.maxLength"
              class="text-danger"
            >
              Tên thư mục có độ dài tối đa
              {{ $v.queryBody.name.$params.maxLength.min }} ký tự.</small>
          </b-form-group>
          <b-form-group>
            <label>Mô tả</label>
            <b-form-textarea
              id="InputHelp"
              v-model="queryBody.description"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NFormInput from '@/views/ndev-component/component/NFormInput.vue'
import ServerFileConstantsApi from '@/views/ndev-component/constants/ServerFileConstantsApi'

export default {
  components: {
    NFormInput,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalLibraryFolder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataFolderDetail: {
      type: Array,
      default: _ => {
      },
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_BreadCom']),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      queryBody: {
        name: '',
        description: '',
        parentId: this.parentId,
      },
    }
  },
  validations: {
    queryBody: {
      name: {
        required,
        // minLength: minLength(4),
        maxLength: maxLength(500),
      },
    },
  },
  watch: {
    dataFolderDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            id: this.dataFolderDetail.id,
            name: this.dataFolderDetail.name,
            description: this.dataFolderDetail.description,
            parentId: this.dataFolderDetail.parentId,
          }
          this.txtNameLibraryFolderEvent.focus()
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            description: '',
            parentId: this.parentId,
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_reloadFolder']),
    async handleCreateFolder(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
      } else {
        // this.$emit('handleCreateFolder', this.queryBody)
        this.queryBody.parentId = this.parentId
        if (this.type === 'edit') {
          const iResult = await NCoreHelper.excutePUT(this, ServerFileConstantsApi.SERVER_FILE_UPDATE_FOLDER, this.queryBody, 'Cập nhật thư mục thành công')
          if (iResult) {
            this.$emit('handleUpdateFolder', iResult)
            // this.libraryStore_action_reloadFolder(true)
          }
        }
        if (this.type === 'add') {
          const iResult = await NCoreHelper.excutePOST(this, ServerFileConstantsApi.SERVER_FILE_CREATE_FOLDER, this.queryBody, 'Thêm thư mục thành công')
          if (iResult) {
            this.$emit('handleUpdateFolder', iResult)
            // this.libraryStore_action_reloadFolder(true)
          }
        }
      }
    },
    resetState() {
      this.queryBody = {
        name: '',
        description: '',
        parentId: this.parentId,
      }
    },
  },
}
</script>
